import React, { useState, useEffect }  from 'react';
// import { Hits } from "react-instantsearch-dom";
import { Card } from "./Card";
import HashLinkObserver from "react-hash-link";
import {Filter} from "./Filter";

// export function useStats(props) {
//     return useConnector(connectStats, props);
// }
//
// export function Stats(props) {
//     const { nbHits } = useStats(props);
//
//     return <>Showing {nbHits} Data Point{nbHits > 1 ? 's' : null}</>;
// }

import { connectHits } from 'react-instantsearch-dom';

const Hits = ({ hits }) => (
    <div className={'grid relative grid-cols-1 lg:grid-cols-2 gap-9'}>
        {hits.map(hit => (
            <Card key={hit.ObjectID} hit={hit} />
        ))}
    </div>
);

const CustomHits = connectHits(Hits);

export function DataApp(){
    return (
        <>
            <HashLinkObserver smoothScroll={true} />
            <div>
                <Filter />

                <section className="py-10 results">
                    <div className="mx-auto container 2xl:mx-auto py-9">
                        <CustomHits />
                    </div>
                </section>
            </div>
        </>
    );
}