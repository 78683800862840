import { createRoot } from "react-dom/client";
import {DataApp} from './components/DataApp';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';

const searchClient = algoliasearch('64LMXTN0XN', '79c4a700b51a2af70dd807fb5a61decb');

const App = () => {
    return (
        <InstantSearch searchClient={searchClient} indexName="prod_state-of-finops">
            <DataApp />
        </InstantSearch>
    );
}

createRoot(document.getElementById("dataApp")).render(<App />);