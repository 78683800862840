import React, {useEffect} from "react";
import { OpenCard } from "./OpenCard";
import InnerHTML from 'dangerously-set-html-content';

const useHash = () => {
    const [hash, setHash] = React.useState(() => window.location.hash);

    const hashChangeHandler = React.useCallback(() => {
        setHash(window.location.hash);
    }, []);

    React.useEffect(() => {
        window.addEventListener('hashchange', hashChangeHandler);
        return () => {
            window.removeEventListener('hashchange', hashChangeHandler);
        };
    }, []);

    const updateHash = React.useCallback(
        newHash => {
            if (newHash !== hash) window.location.hash = newHash;
        },
        [hash]
    );

    return [hash, updateHash];
};

export const Card = ({ hit }) => {
    const [isOpen, setOpen] = React.useState(false);
    const [hash, setHash] = useHash();

    const copyToClipboard = (ID = null) => {
        let card = document.getElementById('card-' + ID);

        let aux = document.createElement("div");
            aux.setAttribute("contentEditable", true);
            aux.innerHTML = location.protocol+'//'+location.host+location.pathname + '#' + ID;
            aux.style.position = "absolute";
            aux.style.left = "-9999px";
            aux.setAttribute("onfocus", "document.execCommand('selectAll',false,null)");

        card.appendChild(aux);
        aux.focus();
        document.execCommand("copy");
        // document.body.removeChild(aux);

        return false;
    }

    const toggleOpen = (ID = null) => {
        if (ID){
            history.pushState("", document.title, window.location.pathname + window.location.search + '#' + ID);

            const element = document.getElementById('card-' + ID);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center'
                });
            }
        }
        else {
            history.pushState("", document.title, window.location.pathname + window.location.search);
        }

        setOpen(!isOpen);
    }

    useEffect(() => {
        if(window.location.hash.length > 0 && window.location.hash.replace('#', '') === hit.objectID){
            setTimeout(() => {
                const element = document.getElementById(window.location.hash.replace('#', 'card-'));
                if (element) {
                    element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                    });
                    setOpen(true);
                }
            }, 100)
        }
    }, []);

    return (
        <>
            <div id={'card-' + hit.objectID} className="card md:pb-20 h-full relative bg-white rounded-lg">
                <div className="graph relative p-5 md:p-8">
                    <div className="bg-gray-50 h-[350px]">
                        {hit.chart_image &&
                            <div className="flex items-center justify-center h-full"><img className={' h-[250px]'} src={hit.chart_image} alt=""/></div>
                        }

                        {hit.chart_code &&
                            <div className="flex items-center justify-center h-full"><InnerHTML className={'w-11/12 h-[250px]'} html={hit.chart_code.replace('id=""', 'id="small_'+hit.objectID+'"').replace('getElementById("")', 'getElementById("small_'+hit.objectID+'")')}/></div>
                        }
                    </div>
                </div>

                <div className="pt-0 p-5 md:p-8">
                    {hit.years.map((year, index) => (
                        <div className="bg-gray-100 mr-2 inline-block mb-2 px-2 rounded-xs text-sm text-gray-600">
                            <span key={index}>{year}</span>
                        </div>
                    ))}
                    <h3 className="text-2xl text-slate-100 font-bold mb-3" dangerouslySetInnerHTML={{__html: hit.title}}/>
                    <p className={''} dangerouslySetInnerHTML={{__html: hit.excerpt + (hit.excerpt !== hit.content ? '...' : '')}}/>

                    <div className="md:absolute mt-5 md:mt-5 bottom-8 left-8">
                        <button onClick={() => toggleOpen(hit.objectID)} className="inline-flex justify-center py-2 text-green-900 px-5 text-md font-medium rounded border border-green-500 bg-green-500 hover:bg-green-300 hover:border-green-300 font-normal">Expand</button>

                        <button onClick={() => copyToClipboard(hit.objectID)} className="inline-flex justify-center py-2 px-5 text-md font-medium rounded border border-gray-100 hover:border-gray-300 font-normal ml-4">Copy Link</button>
                    </div>
                </div>
            </div>

            {isOpen && <OpenCard hit={hit} copyToClipboard={(ID) => copyToClipboard(ID)} toggleOpen={() => toggleOpen()} />}
        </>
    )
}