import React from "react";
import {SearchBox, connectStats, connectMenu} from "react-instantsearch-dom";

function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const Stats = ({ nbHits }) => <p>Showing {nbHits} Data Point{nbHits > 1 ? 's' : ''}</p>;
const CustomStats = connectStats(Stats);

const MenuSelect = ({ items, currentRefinement, refine, emptyText }) => (
    <select
        value={currentRefinement || ''}
        onChange={event => refine(event.currentTarget.value)}
    >
        <option value="">{emptyText}</option>
        {items.map(item => (
            <option
                key={item.label}
                value={item.isRefined ? currentRefinement : item.value}
            >
                {item.label}
            </option>
        ))}
    </select>
);

const CustomMenuSelect = connectMenu(MenuSelect);

export const Filter = () => {
    return (
        <section className="filters bg-white">
            <div className=" py-4 px-10 mx-auto">
                <div className="flex items-center gap-6">
                    <div className={'select w-[240px]'}>
                        <CustomMenuSelect limit={100} emptyText={'All Capabilities'} defaultRefinement={getParameterByName('capability')} attribute="related_capabilities.title"/>
                        <p className="hidden text-gray-600 text-sm mt-2"><CustomStats /></p>

                        <svg style={{pointerEvents: 'none'}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.19526 5.52827C2.45561 5.26792 2.87772 5.26792 3.13807 5.52827L8 10.3902L12.8619 5.52827C13.1223 5.26792 13.5444 5.26792 13.8047 5.52827C14.0651 5.78862 14.0651 6.21073 13.8047 6.47108L8.4714 11.8044C8.21106 12.0648 7.78895 12.0648 7.5286 11.8044L2.19526 6.47108C1.93491 6.21073 1.93491 5.78862 2.19526 5.52827Z" fill="black" fill-opacity="0.92"/>
                        </svg>

                    </div>

                    <div className={'select w-[240px]'}>
                        <CustomMenuSelect emptyText={'All Years'} attribute="years"/>

                        <svg style={{pointerEvents: 'none'}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.19526 5.52827C2.45561 5.26792 2.87772 5.26792 3.13807 5.52827L8 10.3902L12.8619 5.52827C13.1223 5.26792 13.5444 5.26792 13.8047 5.52827C14.0651 5.78862 14.0651 6.21073 13.8047 6.47108L8.4714 11.8044C8.21106 12.0648 7.78895 12.0648 7.5286 11.8044L2.19526 6.47108C1.93491 6.21073 1.93491 5.78862 2.19526 5.52827Z" fill="black" fill-opacity="0.92"/>
                        </svg>
                    </div>

                    <div className={'w-[240px] search'}>
                        <SearchBox className={'w-full'} />

                        <svg style={{pointerEvents: 'none'}} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.2191 12.4956C11.0698 13.4361 9.60076 14.0003 7.99992 14.0003C4.31802 14.0003 1.33325 11.0156 1.33325 7.33366C1.33325 3.65176 4.31802 0.666992 7.99992 0.666992C11.6818 0.666992 14.6666 3.65176 14.6666 7.33366C14.6666 8.93452 14.1023 10.4036 13.1619 11.5528L15.138 13.529C15.3983 13.7893 15.3983 14.2114 15.138 14.4718C14.8777 14.7321 14.4555 14.7321 14.1952 14.4718L12.2191 12.4956ZM13.3333 7.33366C13.3333 10.2792 10.9454 12.667 7.99992 12.667C5.0544 12.667 2.66659 10.2792 2.66659 7.33366C2.66659 4.38814 5.0544 2.00033 7.99992 2.00033C10.9454 2.00033 13.3333 4.38814 13.3333 7.33366Z" fill="black" fill-opacity="0.92"/>
                        </svg>

                    </div>
                </div>
            </div>
        </section>
    )
}