import React from "react";
import InnerHTML from "dangerously-set-html-content";

export class OpenCard extends React.Component {
    constructor() {
        super();

        this.state = {
            loaded: false
        };
    }

    componentDidMount() {
        window.requestAnimationFrame(() => this.setState({loaded: true}));
    }

    render() {
        return (
            <>
                <div onClick={() => {this.props.toggleOpen()}} className="bg-gray-800 opacity-50 h-[100vh] w-[100vw] fixed top-0 left-0 z-10"/>

                <div className={"card open-card fixed top-0 z-11 h-full left-0 card-full bg-white col-span-3 rounded-lg  " + (this.state.loaded ? 'active' : '')}>
                    <div className={'grid grid-cols-12'} style={{height: 720}}>
                        <div className="graph bg-gray-50 col-span-12 lg:col-span-7 flex items-center justify-center p-8">
                            {this.props.hit.chart_image &&
                                <img src={this.props.hit.chart_image} alt=""/>
                            }

                            {this.props.hit.chart_code &&
                                <InnerHTML className={'canvas-container'} html={this.props.hit.chart_code.replace('id=""', 'id="large_'+this.props.hit.objectID+'"').replace('getElementById("")', 'getElementById("large_'+this.props.hit.objectID+'")')}/>
                            }
                        </div>

                        <div className="p-8 pb-[9rem] relative col-span-12 lg:col-span-5">
                            {this.props.hit.years.map((year, index) => (
                                <div className="bg-gray-100 mr-2 inline-block mb-2 px-2 rounded-xs text-sm text-gray-600">
                                    <span key={index}>{year}</span>
                                </div>
                            ))}

                            <h3 className="text-2xl mb-3 md:mt-5 mt-3 font-medium" dangerouslySetInnerHTML={{__html: this.props.hit.title}} />
                            <p dangerouslySetInnerHTML={{__html: this.props.hit.content}}/>

                            {!!this.props.hit.related_capabilities && this.props.hit.related_capabilities.length > 0 &&
                                <>
                                    <h3 className="mt-10 font-semibold text-xl text-gray-800">Related Framework Capabilities</h3>
                                    <p>View maturity information, benchmarks and key performance indicators.</p>

                                    <ul>
                                        {!!this.props.hit.related_capabilities && this.props.hit.related_capabilities.map((capability, index) => (
                                            <li key={index}><a className={'text-green-500'} href={capability.url}>{capability.title}</a></li>
                                        ))}
                                    </ul>
                                </>
                            }

                            <small className="lg:absolute mt-4 text-gray-600 text-sm bottom-[6rem] block left-8">Source: {this.props.hit.source}{this.props.hit.show_how_to_attribute_link ? <span> - <a class="text-green-500" href="https://finops.org/introduction/how-to-use/?__hstc=181257784.74da63e6b63b6d8f44ef9bdb24a14c42.1693312584865.1708450572284.1708524956564.222&__hssc=181257784.1.1708524956564&__hsfp=4031198360" target="_blank">How to attribute.</a></span> : null}</small>
                            {this.props.hit.n_number ? <small className="lg:absolute block text-gray-600 text-sm bottom-[6rem] right-8">n={this.props.hit.n_number}</small> : null}

                            <div className="absolute bottom-8 right-8">
                                <button onClick={() => this.props.toggleOpen()} className="inline-flex justify-center py-2 px-5 text-md font-medium rounded border border-gray-100 hover:border-gray-300 font-normal mr-2">Close</button>

                                <button onClick={() => this.props.copyToClipboard(this.props.hit.objectID)} className="inline-flex justify-center py-2 text-green-900 px-5 text-md ml-4 font-medium rounded border border-green-500 bg-green-500 hover:bg-green-300 hover:border-green-300 font-normal">Copy Link</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}